<template>
  <div>
    <img class="top-shadow" src="https://cdn.cailu88.com/jingxuanshi/about_top_shadow.png?v=1"/>
    <img class="bottom-shadow" src="https://cdn.cailu88.com/jingxuanshi/about_bottom_shadow.png?v=1"/>
    <div class="about">

      <img class="logo" src="https://cdn.cailu88.com/jingxuanshi/about_logo.png"/>
      <div class="contentText">
        鲸选师是一款专业社交电商导购平台APP，通过技术和运营方式遴选优质低价的商品，抓取全网隐藏优惠，为消费者提供特惠商品与优质服务。同时以社交为核心，帮助用户以快捷的分享方式传递给身边的人，从而赋能普通人电商卖货能力，降低门槛，助力大众0成本创业。
        <br/>
        <br/>
        目前，鲸选师有淘宝、京东、拼多多等主流电商平台的商品，商品有保障。鲸选师战略合作伙伴京拍档作为京东商家代运营服务商龙头企业，也将帮助鲸选师一起为用户提供更多、更好、更省的优质商品。后续还将加入更多网络购物及生活服务平台，不断扩充产品服务能力。
        <br/>
        <br/>
        鲸选师创始人恒星以及核心团队均来自于阿里巴巴、京东、网易等知名互联网公司，拥有丰富的电商运营经验，专注电商导购业务。公司特邀请谢云立（原腾讯电商助理总经理、京东微信手Q事业部营销总经理）和王文峰（京东第一家代运营服务商京拍档CEO兼创始人）担任资深顾问，共同打造这款能帮消费者省钱又赚钱的社交电商平台，引领未来的商品交易模式和新社交关系。
      </div>
      <div class="orangeColor">
        <span @click="toDetails(1)">《鲸选师用户协议》</span><span @click="toDetails(2)">《鲸选师隐私政策》</span>
      </div>
      <div class="version" v-if="this.v">当前版本号：v{{ v }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'about',
  data () {
    return {
      v: ''
    }
  },
  mounted: function () {
    window.scrollTo({
      top: 0
    })
    this.v = this.$route.query.v
  },
  methods: {
    toDetails (type) {
      type === 1 ? this.$router.push({ name: 'agreement' }) : this.$router.push({ name: 'privacy' })
    }
  }
}
</script>

<style lang="less">
/* pages/about/index.wxss */

.about {
  width: 310px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-shadow {
  position: absolute;
  width: 47px;
  height: 101px;
  top: 54px;
  right: 0;
}

.bottom-shadow {
  position: absolute;
  width: 37px;
  height: 92px;
  top: 532px;
  left: 0;
}

.about .logo {
  width: 205px;
  height: 142px;
  margin-top: 33px;
}


.about .contentText {
  font-size: 15px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 33px;
  margin-top: 20px;
}

.about .orangeColor {
  width: 340px;
  height: 21px;
  text-align: center;
  font-size: 12px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #C99040;
  line-height: 21px;
}

.about .version {
  width: 340px;
  height: 21px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  margin-top: 15px;
  margin-bottom: 24px;
}
</style>
